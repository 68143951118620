// export const baseurl ="http://10.39.1.76/findproperties/public"
// export const baseurlImg  ="http://10.39.1.76/findproperties"

export const baseurl = "https://services.findproperties.ae";
export const baseurlImg = "https://findpropertiesbackup1.s3.me-south-1.amazonaws.com";

// export const baseurl ="http://localhost/backend/public"
// export const baseurlImg  ="http://localhost/backend/public"

//https://findproperties.s3.me-south-1.amazonaws.com/uploads/properties/1660565812.zgh2mZSLug.png


export const MAP_API_KEY = "AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s"