import React, { useEffect, useState } from 'react';
import API from '../../../../utils/API';

const UpdateRealestate = ({xCLoseModal,updateData}) => {
    const [formData, setFormData] = useState({
        name_en: '',
        name_ar: '',
        email: '',
        mobile: '',
        location: '',
        location_ar: '',
        url: '',
        logo: null, // For base64 file
    });

    useEffect(() => {
        if (updateData) {
            // Check if updateData contains specific keys before updating formData
            setFormData((prevState) => ({
                ...prevState,
                name_en: updateData.name_en || prevState.name_en,
                name_ar: updateData.name_ar || prevState.name_ar,
                email: updateData.email || prevState.email,
                mobile: updateData.mobile || prevState.mobile,
                location: updateData.location || prevState.location,
                location_ar: updateData.location_ar || prevState.location_ar,
                url: updateData.url || prevState.url,
                logo: updateData.logo || prevState.logo,
            }));
        }
    }, [updateData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, logo: reader.result }); // Base64 encoded string
            };
            reader.readAsDataURL(file); // Convert file to base64
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitData = new FormData();

        // Append each field to FormData
        submitData.append('id', updateData.id);
        submitData.append('name_en', formData.name_en);
        submitData.append('name_ar', formData.name_ar);
        submitData.append('email', formData.email);
        submitData.append('mobile', formData.mobile);
        submitData.append('location', formData.location);
        submitData.append('location_ar', formData.location_ar);

        submitData.append('url', formData.url);

        // Append logo (base64 string)
        if (formData.logo) {
            submitData.append('logo', formData.logo);
        }

        // Submit the FormData
        try {
            const response = await API.post('/realestate_update',submitData);
           
            
           
            if(response.data.success){
                xCLoseModal()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-1">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name_en" className="form-label">Name (English):</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name_en"
                        name="name_en"
                        value={formData.name_en}
                        onChange={handleChange}
                        placeholder="Enter name in English"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="name_ar" className="form-label">Name (Arabic):</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name_ar"
                        name="name_ar"
                        value={formData.name_ar}
                        onChange={handleChange}
                        placeholder="Enter name in Arabic"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">Mobile:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Enter mobile number"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="location" className="form-label">Location:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Enter location"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="location_ar" className="form-label">Location (Arabic):</label>
                    <input
                        type="text"
                        className="form-control"
                        id="location_ar"
                        name="location_ar"
                        value={formData.location_ar}
                        onChange={handleChange}
                        placeholder="Enter location (arabic)"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="url" className="form-label">URL:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="url"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        placeholder="Enter URL"
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="logo" className="form-label">Logo:</label>
                    <input
                        type="file"
                        className="form-control"
                        id="logo"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </div>

                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default UpdateRealestate;
