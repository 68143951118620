import React, { Component } from "react";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import API from "../../../utils/API";

import "antd/dist/antd.css";
import {Table,Breadcrumb,DatePicker} from 'antd'
import { ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import { Grid } from "react-loader-spinner";
import moment from "moment";
import {Link} from 'react-router-dom'
const { RangePicker } = DatePicker;


function RelativeTime({ date }) {
  if (!date) {
    return <span></span>; // Return empty if date is null or undefined
  }
  return <span>{moment(date).fromNow()}</span>;
}
class AgencyLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      dateRange:null
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    const dateRange = this.state.dateRange
    this.fetchData(page, perPage,dateRange);
  }

  fetchData = (page, perPage,dateRange) => {
    this.setState({ isLoading: true });
    API.get(`/agencyleads?page=${page}&perPage=${perPage}&date=${dateRange}`)
      .then(async (response) => {
        await response.data.leads;

        this.setState({ isLoading: false, leads: response.data.leads });
      })
      .catch((e) => console.log("error"));
  };

  

  render() {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";

 
    const columns = [
      {
        title: lang == "en" ? "Title" : "عنوان العقار",
        render: (text, record) => (
          <a
          href={"https://findproperties.ae/single-property/" + record.id}
       
          title={lang == "en" ? "Show" : "عرض"}
          target="_blank"
        >
          {lang == "en" ? record.title_en : record.title_ar}
           </a>
        ),
      },
      {
        title: lang == "en" ? "Location" : "الموقع",
        render: (text, record) => (
          <>
            {lang == "en"
              ? record.emirate_en + "-" + record.area_en
              : record.emirate_ar + "-" + record.area_ar}
          </>
        ),
      },
      {
        title: lang == "en" ? "Clicks" : "النقرات", 
        render: (text, record) => (
          <span class="badge bg-primary">{record.viewing_count}</span>
        ),
      },
      {
        title: lang == "en" ? "Last click" : "اخر زيارة",
        render: (text, record) => (
          <RelativeTime date={record.last_viewing} />
          // <span >{record.last_viewing}</span>
        ),
      },
      {
        title:lang == "en" ? "Calls" : "حالات الاتصال", 
        render: (text, record) => (
          <span class="badge bg-danger text-black">{record.calls_count}</span>
        ),
      },
      {
        title: lang == "en" ? "Last call" : " اخر اتصال",
        render: (text, record) => (
          <RelativeTime date={record.last_call} />
          // <span >{record.last_call}</span>
        ),
      },
      {
        title: lang == "en" ? "Whatsapp" : "واتساب",
        render: (text, record) => (
          <span class="badge bg-success text-black">
            {record.whatsapp_count}
          </span>
        ),
      },
      {
        title: lang == "en" ? "Last message" : "اخر رسالة",
        render: (text, record) => (
          <RelativeTime date={record.last_whatsapp} />
          // <span >{record.last_whatsapp}</span>
        ),
      },

    ];
    const handleTableChange = (pagination) => {
      const dateRange = this.state.dateRange
      this.fetchData(pagination.current, pagination.pageSize,dateRange);
    };

    const handleRangeDate = (date, dateString) => {
      this.setState({dateRange:date?dateString:""})
      const page = 1;
      const perPage = 10;
      const dateRange = date?dateString:""
      this.fetchData(page, perPage,dateRange);

      

    }

    return (
      <div>
        <div className="container-fluid">
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
            <Breadcrumb.Item>
              <Link to="/">{lang=="en"?"Dashboard":"لوحة البيانات"}</Link>
            
            </Breadcrumb.Item>

          <Breadcrumb.Item>{lang=="en"?"Leads":"العملاء المحتملون"}</Breadcrumb.Item>
          
        </Breadcrumb>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  
                </div>
                <div className="card-body">
                <div style={{display:'flex',flexDirection:'row',marginBottom:'10px',justifyContent:'space-between'}}>
                
                  
                    <div>
                    <label style={{padding:'10px'}}>{lang =="en"?"Date":"التاريخ"} </label>
                    <RangePicker
                    onChange={handleRangeDate}
                      className="custom-range-picker"
                      dropdownClassName="custom-dropdown"
                    />

                    </div>
                  </div>
                  <div className="table-responsive">
              
                      <Table
                        className="table-striped"
                        onChange={handleTableChange}
                        loading={this.state.isLoading}
                        pagination={{
                          pageSizeOptions: ["5", "10", "20", "50", "100"],
                          total: this.state?.leads?.total > 0 && this.state.leads.total,
                          pageSize: this.state.leads.per_page,
                          current: this.state.leads.current_page,
                          showTotal: (total, range) =>
                          lang === "en"
                              ? `Showing ${range[0]} to ${range[1]} of ${total} entries`
                              : `عرض ${range[0]} إلى ${range[1]} من إجمالي ${total} إدخالاً`, // Arabic
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          locale: {
                            items_per_page: lang === "en" ? "/page" : "/صفحة", // Dynamic language for the `/page` text
                          },
                          itemRender: (current, type, originalElement) => {
                            if (type === "prev") {
                              return lang === "en" ? <a>Previous</a> : <a>السابق</a>; // Arabic
                            }
                            if (type === "next") {
                              return lang === "en" ? <a>Next</a> : <a>التالي</a>; // Arabic
                            }
                            
                            return originalElement;
                          },
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                        bordered={true} 
                        dataSource={
                          this.state?.leads?.data ? this.state?.leads?.data : []
                        }
                        rowKey={(record) => record}
                      />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgencyLeads;
