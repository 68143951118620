import React, { Component } from "react";

import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "react-loader-spinner";

import { baseurlImg } from "../../../../Components/BaseUrl";
import API from "../../../../utils/API";
const lang = localStorage.getItem("lang") || "en";

class AgencyAgentsAndProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });

    API.get(`/agency-agency?page=${page}&perPage=${perPage}`)
      .then(async (response) => {
        await response.data.agencies;
        this.setState({ isLoading: false, agencies: response.data.agencies });
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const lang = localStorage.getItem("lang") || "en";

    const columns = [
      {
        title: lang == "en" ? "Company Name" : "اسم الشركة",
        render: (text, record) =>
          lang == "en" ? record.name_en : record.name_ar,
      },
      {
        title: lang == "en" ? "Logo" : "الشعار",
        render: (text, record) => (
          <img
            style={{ height: "70px", width: "70px" }}
            src={baseurlImg + "/uploads/profiles/" + record.logo}
          />
        ),
      },

      {
        title: lang == "en" ? "Mobile" : "الجوال",
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: lang == "en" ? " Email" : "البريد الالكتروني",
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },

      {
        title: lang == "en" ? "Number of Agents" : "عدد الوسطاء",
        render: (text, record) =>
          record.agent.length > 0 && record.agent[0].agent_count,
      },
      {
        title: lang == "en" ? "Number of Propterties" : "عدد العقارات",
        render: (text, record) =>
          record.property.length > 0 && record.property[0].property_count,
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
          {/*Modal to Add Category  */}

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {lang == "en" ? "Companies" : "الشركات"}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
               
                      <Table
                        className="table-striped"
                        loading={this.state.isLoading}
                        onChange={handleTableChange}
                        pagination={{
                          pageSizeOptions: ["10", "20", "50", "100"],
                          total:
                            this.state?.agencies?.total > 0 &&
                            this.state.agencies.total,
                          pageSize: this.state.agencies.per_page,
                          current: this.state.agencies.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                        // bordered
                        dataSource={
                          this.state?.agencies?.data
                            ? this.state?.agencies?.data
                            : []
                        }
                        rowKey={(record) => record}
                      />
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgencyAgentsAndProperties;
