import React, { Component } from "react";
import AdminView from "./AdminView";
import AgencyView from "./AgencyView";
import AgentView from "./AgentView";
import SeoView from "./SeoView";
import { store } from "../../store";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      purposeArr: [],
      purposebymonthArr: [],
      rents: 0,
      sales: 0,
      agents: 0,
    };
  }

  render() {
    const userdata = store.getState((state) => state);
    const role = userdata.auth.user.role;
    return (
      <div>
        {role == 1 ? <AdminView /> : null}
        {role == 2 ? <AgencyView /> : null}
        {role == 3 ? <AgentView /> : null}
        {role == 6 ? <SeoView /> : null}
      </div>
    );
  }
}

export default AdminDashboard;
