import React, { Component } from "react";

import i18next from "i18next";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";

import { baseurlImg } from "../../../Components/BaseUrl";
import "antd/dist/antd.css";
import { Table, Breadcrumb } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
//import toast, { Toaster } from 'react-hot-toast';

import { ToastContainer, toast } from "react-toastify";
import API from "../../../utils/API";

class Agents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ isLoading: true });

    API.get("/agents")
      .then((response) => {
        this.setState({ isLoading: false, agents: response.data.agents });
      })
      .catch((err) => console.log("error"));
  };
  handelDelete = (agent, e) => {
    e.preventDefault();
    if (!agent || agent < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/agents/" + agent)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.fetchData();
              //this.setState({categories:response.data.categories})
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  render() {
    const lang = localStorage.getItem("lang") || "en";

    const columns = [
      {
        title: i18next.t("agentname"),
        dataIndex: lang == "en" ? "name_en" : "name_ar",
        sorter: (a, b) => a.name_en.length - b.name_en.length,
      },
      {
        title: i18next.t("email"),
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: i18next.t("mobile"),
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: i18next.t("profile"),
        render: (text, record) => (
          <>
            {record.profile != "" ||
            record.profile != null ||
            !record.profile ? (
              <img
                style={{ height: "70px", width: "70px",borderRadius:'50%' }}
                src={baseurlImg + "/uploads/profiles/" + record.profile}
              />
            ) : (
              <img
                style={{ height: "70px", width: "70px",borderRadius:'50%' }}
                src={baseurlImg + "/public/uploads/profiles/no_avatar.png"}
              />
            )}
          </>
        ),
      },
      {
        title: i18next.t("status"),
        render: (text, record) => (
          <>
            {record.active == 1 ? (
              <span className="badge light badge-success">Active</span>
            ) : (
              <span className="badge light badge-danger">No active</span>
            )}
          </>
        ),
      },

      {
        title: "Action",
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              className="btn btn-sm btn-primary shadow m-1"
              to={"edit-agent/" + record.id}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <a
              className="btn btn-danger btn-sm shadow m-1 "
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div className="container-fluid">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
              <Breadcrumb.Item>
              <Link to="/">{lang=="en"?"Dashboard":"لوحة البيانات"}</Link>
            
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {lang == "en" ? "Agents " : "الوكلاء"}
            </Breadcrumb.Item>
          </Breadcrumb>

          <ToastContainer />
          <div className="row mb-4">
            <div className="card">
              <div className="col-md-12">
                <div className="card-body">
                  <div className="mt-5" />
                  <hr />
                  <div class="table-responsive">
                    <Table
                      className="table-striped"
                      loading={this.state.isLoading}
                      pagination={{
                        pageSizeOptions: ["5", "10", "20", "50", "100"],
                        total: this.state?.agents?.total > 0 && this.state.agents.total,
                        pageSize: this.state.agents.per_page,
                        current: this.state.agents.current_page,
                        showTotal: (total, range) =>
                        lang === "en"
                            ? `Showing ${range[0]} to ${range[1]} of ${total} entries`
                            : `عرض ${range[0]} إلى ${range[1]} من إجمالي ${total} إدخالاً`, // Arabic
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        locale: {
                          items_per_page: lang === "en" ? "/page" : "/صفحة", // Dynamic language for the `/page` text
                        },
                        itemRender: (current, type, originalElement) => {
                          if (type === "prev") {
                            return lang === "en" ? <a>Previous</a> : <a>السابق</a>; // Arabic
                          }
                          if (type === "next") {
                            return lang === "en" ? <a>Next</a> : <a>التالي</a>; // Arabic
                          }
                          
                          return originalElement;
                        },
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={this.state.agents}
                      rowKey={(record) => record}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid">
         
           
    





{!this.state.isLoading? 
 <div className="row">
<div className="col-12">
<div className="card">
  <div className="card-header">
    <h4 className="card-title">Agents</h4>
  </div>
  <div className="card-body">
    <div className="table-responsive">
        {this.state.agents.length !=0 && !this.state.isLoading?    
        <table id="myTable" className="display" style={{minWidth: 845}}>
        <thead>
          <tr>
          <th>{i18next.t("agentname")} </th>
            <th>{i18next.t("email")}</th>
            <th>{i18next.t("mobile")}</th>
            <th>{i18next.t("profile")}</th>
            <th>{i18next.t("status")}</th>
            <th>{i18next.t("actions")}</th>
          </tr>
        </thead>
        <tbody>
            
 {this.state.agents.map((agent) =>(

<tr>
<td>{lang == "en" ? agent.name_en : agent.name_ar}</td>
<td>{agent.email}</td>
<td>{agent.mobile}</td>
{agent.profile != "" ? <td>
    <img style={{ height: '70px', width: '70px' }} src={baseurlImg + '/public/uploads/profiles/' + agent.profile} />
</td> : <td></td>}
<td>
    {agent.active == 1 ? (<span className="badge light badge-success">Active</span>) : (<span className="badge light badge-danger">No active</span>)}
</td>
<td>
    <div className="ms-auto">

        <Link className="btn btn-sm btn-primary shadow" to={"edit-agent/" + agent.id} ><i className="fa fa-edit"></i></Link>
        <a className="btn btn-danger btn-sm shadow  " onClick={this.handelDelete.bind(this, agent.id)}><i className="fa fa-trash" ></i></a>
    </div>
</td>

</tr>
            )

            )}


        </tbody>
     
      </table>:"loading"}
  
    </div>
  </div>
</div>
</div>



</div>:
      <div className="row" style={{justifyContent:'center',alignItems:'center',alignContent:'center'}}>
      <div className="col-md-4"></div>

      <div className="col-md-4" style={{position: 'absolute', top: '50%',margin: '0 auto',left: '50%'}}>
      <div class="lds-ripple"><div></div><div></div></div>
      </div>

      <div className="col-md-4"></div>
  </div>


}

</div> */}
      </div>
    );
  }
}

export default Agents;
