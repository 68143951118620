import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import PublicRoute from "./Components/protectRoutes/PublicRoute";

import TechSupport from "./TechSupport";

import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

import SidebarComponent from "./Components/Sidebar/Sidebar";
import { store } from "./store";


class App extends React.Component {



  render() {
  

    const lang = localStorage.getItem("lang") || "en";
    const userdata = store.getState(state=> state);
    let authenticated= userdata.auth.loggedIn
    return (
      <Router>
        <Switch>
 
              <Route exact path="/tech-support" component={TechSupport} />
               <PublicRoute exact path="/" component={Login} />
              <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />
               <PublicRoute
                exact
                path="/reset-password/:id"
                component={ResetPassword}
              />
       
            <SidebarComponent />
       

          
        </Switch>
      </Router>

    );
  }
}
export default App;
