import React, { Component } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { baseurl } from "../../../../Components/BaseUrl";
import Moment from "react-moment";
import API from "../../../../utils/API";
import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import { Grid } from "react-loader-spinner";
import moment from "moment";

function RelativeTime({ date }) {
  if (!date) {
    return <span></span>; // Return empty if date is null or undefined
  }
  return <span>{moment(date).fromNow()}</span>;
}
class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calls: [],
      agency: {},
      totalclicks: 0,
      isLoading: false,
    };
  }
  componentDidMount() {
    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage);
  }
  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    let id = this.props.match.params.id;

    API.get(`/calls/${id}?page=${page}&perPage=${perPage}`)
      .then(async (res) => {
        await res.data.calls;
        this.setState({
          isLoading: false,
          calls: res.data.calls,
          agency: res.data.agency,
        });
      })
      .catch((e) => console.log("error "));
  };

  render() {
    const columns = [
      {
        title: "Time",
        render: (text, record) => (
          <RelativeTime date={record.created_at} />
          // <Moment format="YYYY/MM/DD">{record.created_at}</Moment>
        ),
      },

      {
        title: "Property No",
        render: (text, record) => record.property_id,
      },
      {
        title: "Property Title",
        render: (text, record) => record.title_en,
      },
      {
        title: "Country",
        render: (text, record) => record.country,
      },
      {
        title: "IP",
        render: (text, record) => record.ip_address,
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
          {/* row */}

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    Calls / {this.state.agency.name_en}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      loading={this.state.isLoading}
                      onChange={handleTableChange}
                      pagination={{
                        pageSizeOptions: ["10", "20", "50", "100"],
                        total:
                          this.state?.calls?.total > 0 &&
                          this.state.calls.total,
                        pageSize: this.state.calls.per_page,
                        current: this.state.calls.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto", textAlign: "right" }}
                      columns={columns}
                      // bordered
                      dataSource={
                        this.state?.calls?.data ? this.state?.calls?.data : []
                      }
                      rowKey={(record) => record}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Call;
