import React, { Component } from 'react'

 class SeoView extends Component {
     componentDidMount(){
       
     }
    render() {
        return (
            <div style={{padding:'10px'}}>
               dashboard  
            </div>
        )
    }
}

export default SeoView
