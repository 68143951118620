import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

 class Notfound extends Component {
    
    render() {
        
        return (
            <div>
                Not found 404
                <Link to="/">Go Dashboard</Link>
            </div>
        )
    }
}

export default Notfound
