import React, { Component } from "react";

import i18next from "i18next";
import Swal from "sweetalert2";

import cookie from "js-cookie";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { Table, Breadcrumb } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../../utils/API";

const lang = localStorage.getItem("lang") || "en";
const token = cookie.get("token");
class AgentDraftlisting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managelistings: [],
      status: [],
      rent_count: 0,
      buy_count: 0,
      all_count: 0,
      rent_button: false,
      buy_button: false,
      all_button: true,
      selected_button: 4,
      isLoading: false,
      propertytypes: [],
      locations: [],
      filterLocation: [],
      selectedLocations: [],
      purpose: 0,
      category_id: 0,
      beds: -1,
      select_purpose: 0,
      dateFrom: "",
      dateTo: "",
    };
  }
  componentDidMount() {
    const page = 1;
    const perPage = 10;
    const data = {
      selected_button: 4,
      selectedLocations: [],
      purpose: 0,
      select_purpose: 0,
      category_id: 0,
      beds: -1,
    };
    API.get("/allpropertytypes")
    .then((response) => {
      this.setState({
        propertytypes: response.data.propertytypes,
      });
    })
    .catch();
  API.get("/agentfilterdraftLocation")
    .then((response) => {
      this.setState({
        filterLocation:
          lang == "en" ? response.data.locations : response.data.locations_ar,
      });
    })
    .catch((e) => console.log("error"));
    this.fetchData(page, perPage, data);
  }
  currencyFormat = (num) => {

    return 'AED  ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  fetchData = (page, perPage,data) => {
    this.setState({ isLoading: true });
    API.post(`/agent-filterdraft?page=${page}&perPage=${perPage}`,data)
      .then(async (response) => {
        var data = await response.data.managelistings.data;
        var i;
        for (i = 0; i < data.length; i++) {
          if (data[i].package_type == 1) {
            data[i].basicbutton = true;
          } else {
            data[i].basicbutton = false;
          }
          if (data[i].package_type == 2) {
            data[i].featuredbutton = true;
          } else {
            data[i].featuredbutton = false;
          }
          if (data[i].package_type == 3) {
            data[i].premiumbutton = true;
          } else {
            data[i].premiumbutton = false;
          }
        }
        await response.data.managelistings;
        this.setState({
          managelistings: response.data.managelistings,
          status: response.data.status,
          basic_available: response.data.basic_available,
          featured_available: response.data.featured_available,
          premium_available: response.data.premium_available,
          rent_count: response.data.rent_count,
          buy_count: response.data.buy_count,
          all_count: response.data.all_count,
          isLoading: false,
        });
      })
      .catch((err) => console.log("error"));
  
  };
  handelDelete = (managelisting, e) => {
    e.preventDefault();
    if (!managelisting || managelisting < 1) {
      return;
    } else {
      Swal.fire({
        title: lang == "en" ? "Are you sure?" : "هل انت متأكد ؟",
        text:
          lang == "en"
            ? "You won't be able to revert this!"
            : "لايمكنك الرجوع بعد هذه الخطوة",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: lang == "en" ? "Yes, delete it!" : "نعم ،احذف",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/properties/" + managelisting)
            .then((response) => {
              toast.success(
                lang == "en" ? "Successfully deleted." : "تم الحذف بنجاح"
              );

              const data = {
                selected_button: this.state.selected_button,
                selectedLocations: this.state.selectedLocations,
                purpose: this.state.purpose,
                category_id: this.state.category_id,
                beds: this.state.beds,
                select_purpose: this.state.select_purpose,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
              };
          
              const page = 1;
              const perPage = 10;
              this.fetchData(page,perPage,data);
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };
  handleAll = (e) => {
    this.setState({
      selected_button: 4,
      buy_button: false,
      rent_button: false,
      all_button: true,
    });
    const data = {
      selected_button: 4,
      selectedLocations: this.state.selectedLocations,
      purpose: this.state.purpose,
      select_purpose: this.state.select_purpose,
      category_id: this.state.category_id,
      beds: this.state.beds,
    };
    this.fetchDataBy(data);
  };
  handleRent = (e) => {
    this.setState({
      selected_button: 5,
      buy_button: false,
      rent_button: true,
      all_button: false,
    });
    const data = {
      selected_button: 5,
      selectedLocations: this.state.selectedLocations,
      purpose: this.state.purpose,
      select_purpose: this.state.select_purpose,
      category_id: this.state.category_id,
      beds: this.state.beds,
    };
    this.fetchDataBy(data);
  };
  handleSale = (e) => {
    this.setState({
      selected_button: 6,
      buy_button: true,
      rent_button: false,
      all_button: false,
    });
    const data = {
      selected_button: 6,
      selectedLocations: this.state.selectedLocations,
      purpose: this.state.purpose,
      select_purpose: this.state.select_purpose,
      category_id: this.state.category_id,
      beds: this.state.beds,
    };
    this.fetchDataBy(data);
  };
  fetchDataBy = (data) => {


    const page = 1;
    const perPage = 10;
    this.fetchData(page,perPage,data);
  };
  handleDateFrom = (e) => {
    this.setState({ dateFrom: e });
  };

  handleDateTo = (e) => {
    this.setState({ dateTo: e });
  };
  handleSearch = (e) => {
    this.setState({ isLoading: true });

    const data = {
      selected_button: this.state.selected_button,
      selectedLocations: this.state.selectedLocations,
      purpose: this.state.purpose,
      category_id: this.state.category_id,
      beds: this.state.beds,
      select_purpose: this.state.select_purpose,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
    };

    const page = 1;
    const perPage = 10;
    this.fetchData(page,perPage,data);
  };
  handlePurpose = (e) => {
    e.preventDefault();
    this.setState({ select_purpose: e.target.value });
  };
  handleCategory = (e) => {
    e.preventDefault();
    this.setState({ category_id: e.target.value });
  };
  handleBedroom = (e) => {
    e.preventDefault();
    this.setState({ beds: e.target.value });
  };
  handleStatus = (st, managelisting, e) => {
    let index = this.state.managelistings.indexOf(managelisting);
    const data = { property_id: managelisting.id, status_id: st.id };

    API.post("/updateProperty", data)
      .then((response) => {
        if (response.data.success) {
          var mydata = this.state.managelistings;
          var i;
          for (i = 0; i < mydata.length; i++) {
            if (i == index) {
              mydata[i].status_id = st.id;
              mydata[i].status_en = st.status_en;
              mydata[i].status_ar = st.status_ar;
            }
          }
          this.setState({ managelistings: mydata, isLoading: false });
        }
      })
      .catch((e) => console.log("error"));
  };

  handleDraft = (st, managelisting, e) => {
    e.preventDefault();

    //  let index = this.state.managelistings.indexOf(managelisting)
    const data = { property_id: managelisting.id, activation: e.target.value };
    API.post("/changepropertyStatus", data)
      .then((response) => {
        if (response.data.success) {
          lang == "en"
            ? toast.success("Successfully updated Active")
            : toast.success("تم التحويل  لنشط");
            const data = {
              selected_button: this.state.selected_button,
              selectedLocations: this.state.selectedLocations,
              purpose: this.state.purpose,
              category_id: this.state.category_id,
              beds: this.state.beds,
              select_purpose: this.state.select_purpose,
              dateFrom: this.state.dateFrom,
              dateTo: this.state.dateTo,
            };
        
            const page = 1;
            const perPage = 10;
            this.fetchData(page,perPage,data);
        }
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const columns = [
      {
        title: lang=="en"? "Type":"النوع",
        dataIndex: lang=="en"? "typeName_en":"typeName_ar",
      },
      {
        title:  lang=="en"? "Purpose":"الغرض ", 
        render: (text, record) => (
          <>
            {record.purpose == 1 ? i18next.t("forrent") : i18next.t("forbuy")}
          </>
        ),
      },
      {
        title: lang=="en"? "Location ":" الموقع",
        render: (text, record) => (
          <>
            {lang == "en"
              ? record.emirate_en + "-" + record.area_en
              : record.emirate_ar + "-" + record.area_ar}
          </>
        ),
      },
      {
        title:  lang=="en"? " Price":"السعر", 
        dataIndex: "price",
        render:(text,record)=>(
          <>
           {this.currencyFormat(record.price)}
          </>
      )
       // sorter: (a, b) => a.price.length - b.price.length,
      },
      {
        title:  lang=="en"? "Beds ":"الغرف",
        render: (text, record) => (
          <>
            {record.beds > -1
              ? record.beds > 0
                ? record.beds
                :lang=="en"? "Studio":"استديو"
              : null}
          </>
        ),
        // dataIndex: 'beds',
        //  sorter: (a, b) => a.beds.length - b.beds.length,
      },

      
      {
        title:  lang=="en"? "Status":"الحالة",
        render: (text, record) => (
          <select
            className="draft"
            onChange={this.handleDraft.bind(this, 1, record)}
            style={{
              height: "2rem",
              border: "1px solid #cdd4df",
              borderRadius: "0.55rem",
              padding: "5px 15px",
            }}
          >
            {record.activation == 1 ? (
              <>
                <option value="1"> {lang == "en" ? "Active" : "نشط"}</option>
                <option value="0">
                  {lang == "en" ? "Inactive" : "غير نشط"}
                </option>
              </>
            ) : (
              <>
                <option value="0">
                  {lang == "en" ? "Inactive" : "غير نشط"}
                </option>
                <option value="1">{lang == "en" ? "Active" : "نشط"}</option>
              </>
            )}
          </select>
        ),
      },

      {
        title:lang=="en"? "Actions":"العمليات",
        render: (text, record) => (
          <div className="ms-auto">
        
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handelDelete.bind(this, record.id)}
              title={lang == "en" ? "Delete" : "حذف"}
            >
              <i className="bi bi-trash"></i>
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      const data = {
        selected_button: this.state.selected_button,
        selectedLocations: this.state.selectedLocations,
        purpose: this.state.purpose,
        category_id: this.state.category_id,
        beds: this.state.beds,
        select_purpose: this.state.select_purpose,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
      };
      this.fetchData(pagination.current, pagination.pageSize,data);
    };
    return (
      <div>
        <div className="container-fluid">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>
              <Link to="/">{lang=="en"?"Dashboard":"لوحة البيانات"}</Link>
            
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {lang == "en" ? "Draft / Inactive " : "غير نشط / مسودة"}
            </Breadcrumb.Item>
          </Breadcrumb>
          <ToastContainer />
          <div className="row mb-4">
            <div className="card">
              <div className="col-md-12">
                <div className="card-body">
                  <div className="mb-5">
                    <button
                      onClick={this.handleAll}
                      type="button"
                      className={
                        this.state.all_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.all_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.all_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {i18next.t("all")}{" "}
                      <span
                        className={
                          this.state.all_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.all_button
                            ? "green"
                            : "#fff",
                          color: this.state.all_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.all_count}
                      </span>
                    </button>
                    <button
                      onClick={this.handleSale}
                      type="button"
                      className={
                        this.state.buy_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.buy_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.buy_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {lang == "en" ? "SALE" : "للبيع"}{" "}
                      <span
                        className={
                          this.state.buy_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.buy_button
                            ? "green"
                            : "#fff",
                          color: this.state.buy_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.buy_count}
                      </span>
                    </button>
                    <button
                      onClick={this.handleRent}
                      type="button"
                      className={
                        this.state.rent_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.rent_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.rent_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {lang == "en" ? "Rent" : "للايجار"}{" "}
                      <span
                        className={
                          this.state.rent_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.rent_button
                            ? "green"
                            : "#fff",
                          color: this.state.rent_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.rent_count}
                      </span>
                    </button>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-3">
                      <label>{lang == "en" ? "Location" : "الموقع"}</label>
                      <Autocomplete
                        //multiple
                        options={this.state.filterLocation}
                        onChange={(event, newValue) => {
                          this.setState({ selectedLocations: newValue !=null?newValue:[] });
                        }}
                        getOptionLabel={(option) => option.location}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder={lang == "en" ? "location" : "الموقع"}
                            style={{ textAlign: "right" }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>{lang == "en" ? "Purpose" : "الغرض"}</label>
                      <select
                        onChange={this.handlePurpose}
                        className="form-select select2-container--default"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        required
                      >
                        <option selected value="0">
                        {lang=="en"?"Any":"الكل"}
                        </option>
                        <option value="1">
                          {lang == "en" ? "For Rent" : "للايجار"}
                        </option>
                        <option value="2">
                          {lang == "en" ? "For Sale" : "للبيع"}
                        </option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>
                        {lang == "en" ? "Property type" : "نوع العقار"}
                      </label>
                      <select
                        onChange={this.handleCategory}
                        className="form-select select2-container--default"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        required
                      >
                        <option selected value="0">
                        {lang=="en"?"Any":"الكل"}
                        </option>
                        {this.state.propertytypes.map((propertytype) => (
                          <option value={propertytype.id}>
                            {lang == "en"
                              ? propertytype.typeName_en
                              : propertytype.typeName_ar}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>{lang == "en" ? "Beds" : "عدد الغرف"}</label>
                      <select
                        className="form-select form-select-lg mb-3"
                        style={{ fontSize: "unset", height: "2.75rem" }}
                        aria-label="form-select-lg example"
                        onChange={this.handleBedroom}
                      >
                        <option selected value="-1">
                        {lang=="en"?"Any":"الكل"}
                        </option>
                        <option value="0"> {lang=="en"?"Studio":"استديو"}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="20+">20+</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2 mb-3">
                    <div className="col-md-5">
                      <label>{lang == "en" ? "Date From" : "من تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateFrom}
                        onSelect={this.handleDateFrom} //when day is clicked
                        onChange={this.handleDateFrom} //only when value has changed
                      />
                    </div>
                    <div className="col-md-5">
                      <label> {lang == "en" ? "Date To" : " الي تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateTo}
                        onSelect={this.handleDateTo} //when day is clicked
                        onChange={this.handleDateTo} //only when value has changed
                        format="yyyy-MM-dd"
                      />
                    </div>
                    <div className="col-md-1 mt-4">
                      <label style={{ marginTop: "30px" }}></label>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={this.handleSearch}
                      >
                        {lang == "en" ? "Search" : "ابحث"}
                      </button>
                    </div>
                  </div>
                  <div className="mt-5" />

                  <hr />
                  <div class="table-responsive">
                  <Table
                    onChange={handleTableChange}
                    loading={this.state.isLoading}
                    className="table-striped"
                    pagination={{
                      pageSizeOptions: ["5", "10", "20", "50", "100"],
                      total: this.state?.managelistings?.total > 0 && this.state.managelistings.total,
                      pageSize: this.state.managelistings.per_page,
                      current: this.state.managelistings.current_page,
                      showTotal: (total, range) =>
                      lang === "en"
                          ? `Showing ${range[0]} to ${range[1]} of ${total} entries`
                          : `عرض ${range[0]} إلى ${range[1]} من إجمالي ${total} إدخالاً`, // Arabic
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      locale: {
                        items_per_page: lang === "en" ? "/page" : "/صفحة", // Dynamic language for the `/page` text
                      },
                      itemRender: (current, type, originalElement) => {
                        if (type === "prev") {
                          return lang === "en" ? <a>Previous</a> : <a>السابق</a>; // Arabic
                        }
                        if (type === "next") {
                          return lang === "en" ? <a>Next</a> : <a>التالي</a>; // Arabic
                        }
                        
                        return originalElement;
                      },
                    }}
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    dataSource={this.state?.managelistings?.data || []}
                    rowKey={(record) => record.id}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentDraftlisting;
