import React, { Component } from "react";
import cookie from "js-cookie";
import { baseurlImg } from "../../Components/BaseUrl";

import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import API from "../../utils/API";
import { store } from "../../store";
import { connect } from "react-redux";
import { Avatar,Skeleton } from "antd";
const lang = localStorage.getItem("lang") || "en";

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_ar: "",
      email: "",
      mobile: "",
      image: null,
      imageShow: null,
      agency_summary_en: "",
      agency_summary_ar: "",
      input: {},
      errors: {},
      isLoading:false
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    this.setState({isLoading:true})
    API.post("/profile").then((res) => {

      let input = {};
      input["email"] = res.data.user.email;
      input["mobile"] = res.data.user.mobile;
      this.setState({
        isLoading:false,
        email: res.data.user.email,
        mobile: res.data.user.mobile,
        name: res.data.user.name,
        name_ar: res.data.user.name_ar,
        input: input,
        profile: res.data.user.profile,
        agency_summary_en: res.data.agency.agency_summary_en,
        agency_summary_ar: res.data.agency.agency_summary_ar,
      });
    });
  };

  handleName = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  };

  handleNameAr = (e) => {
    e.preventDefault();
    this.setState({ name_ar: e.target.value });
  };
  handleEmail = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ email: e.target.value, input });
  };

  handleMobile = (e) => {
    e.preventDefault();
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ mobile: e.target.value, input });
  };

  handleUpload = (e) => {
    e.preventDefault();
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
    this.setState({ imageShow: URL.createObjectURL(e.target.files[0]) });
  };
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    

    const olduserdata = store.getState((state) => state);
    let old_user = olduserdata.auth.user;

    const data = {
      name: this.state.name,
      name_ar: this.state.name_ar,
      email: this.state.email,
      mobile: this.state.mobile,
      image: this.state.image,
      old_image:old_user.profile
    };

    if (this.validate()) {
      API.post("/updateprofile", data)
        .then((response) => {
          if (response.data.hasError) {
            this.setState({
              isLoading: false,
              hasError: response.data.hasError,
              success: response.data.success,
            });
            toast.error(response.data.msg);
            return;
          } else {
            if (response?.data) this.props.setpProfiles(response.data);

            Swal.fire({
              title: "Done!",
              text: "Data updated",
              icon: "success",
              timer: 1500,
              button: false,
            });
            let input = {};
            input["email"] = "";
            input["mobile"] = "";
          }
        })
        .catch((err) => console.log("error"));
    }
  };

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your mobile .";
    }

    if (typeof input["mobile"] !== "undefined") {
      var pattern = new RegExp(/^\d{12,12}$/);
      if (!pattern.test(input["mobile"])) {
        isValid = false;
        errors["mobile"] =
          "Please use the correct format, add 971 before your number and complete 10 digits";
      }
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  handleagencySummaryEn = (e) => {
    this.setState({ agency_summary_en: e.target.value });
  };
  handleagencySummaryAr = (e) => {
    this.setState({ agency_summary_ar: e.target.value });
  };

  render() {
    const userdata = store.getState((state) => state);
    let user = userdata.auth.user;

    return (
      <div className="container-fluid">
        <Toaster />

        <Skeleton loading={this.state.isLoading} active avatar>

        {/* row */}
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content">
                  <div className="" />
                </div>
                <div className="profile-info">
                  <div class="profile-photo">
                    {this.state.image != null ? (
                      <img
                        src={this.state.imageShow}
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "35px",
                        }}
                      />
                    ) : (
                      <div>
                        {" "}
                        {user.profile != null ? (
                          <Avatar
                            size="large"
                            style={{
                              backgroundColor: "#87d068",
                              marginRight: "1rem",
                            }}
                            icon={
                              <img
                                src={
                                  baseurlImg +
                                  "/uploads/profiles/" +
                                  user.profile
                                }
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            }
                          />
                        ) : (
                          <Avatar
                            size="large"
                            style={{
                              backgroundColor: "#87d068",
                              marginRight: "1rem",
                            }}
                            icon={
                              <img
                                src="images/profile/profile.png"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="profile-details">
                    <br />
                    <div className="profile-email px-2 pt-2">
                      <h4 className="text-muted mb-0">{user.email}</h4>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-8">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <ul className="nav nav-tabs">
                      <h3>
                        {lang == "en" ? "User Information" : "معلومات المستخدم"}
                      </h3>
                    </ul>
                    <div className="tab-content">
                      <div
                        id="profile-settings"
                        className="tab-pane fade active show"
                      >
                        <div className="pt-3">
                          <div className="settings-form">
                            <form>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    {lang == "en" ? "Name" : " الاسم"}
                                  </label>
                                  <input
                                    type="text"
                                    value={this.state.name}
                                    placeholder=""
                                    className="form-control"
                                    onChange={this.handleName}
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    {lang == "en"
                                      ? "Name arabic"
                                      : "الاسم عربي"}
                                  </label>
                                  <input
                                    type="text"
                                    value={this.state.name_ar}
                                    placeholder=""
                                    className="form-control"
                                    onChange={this.handleNameAr}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    {lang == "en" ? "Email" : "بريد الكتروني"}
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    placeholder=""
                                    className="form-control"
                                    onChange={this.handleEmail}
                                  />
                                  <div className="text-danger">
                                    {this.state.errors.email}
                                  </div>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">
                                    {lang == "en" ? "Mobile" : " جوال"}
                                  </label>
                                  <input
                                    type="text"
                                    name="mobile"
                                    value={this.state.mobile}
                                    placeholder=""
                                    className="form-control"
                                    onChange={this.handleMobile}
                                  />
                                  <div className="text-danger">
                                    {this.state.errors.mobile}
                                  </div>
                                </div>
                                {user.role == 2 ? (
                                  <>
                                    {" "}
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">
                                        {lang == "en"
                                          ? "Summary english"
                                          : " ملخص انجليزي"}
                                      </label>
                                      <textarea
                                        type="text"
                                        value={this.state.agency_summary_en}
                                        placeholder=""
                                        className="form-control"
                                        rows={5}
                                        onChange={this.handleagencySummaryEn}
                                      />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">
                                        {lang == "en"
                                          ? "Summary arabic"
                                          : "ملخص عربي"}
                                      </label>
                                      <textarea
                                        type="text"
                                        value={this.state.agency_summary_ar}
                                        placeholder=""
                                        className="form-control"
                                        rows={5}
                                        onChange={this.handleagencySummaryAr}
                                      />
                                    </div>
                                  </>
                                ) : null}

                                <div className="input-group mb-3">
                                  <span className="input-group-text">
                                    {lang == "en"
                                      ? "Profile Image"
                                      : "صورة الملف الشخصي"}
                                  </span>
                                  <div className="form-file">
                                    <input
                                      type="file"
                                      className="form-file-input form-control"
                                      onChange={this.handleUpload}
                                    />
                                  </div>
                                </div>
                              </div>

                              <button
                                className="btn btn-primary"
                                onClick={this.handleSubmit}
                              >
                                {lang == "en" ? "Update" : "تعديل البيانات"}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Skeleton>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setpProfiles: (data) => dispatch({ type: "SET_PROFILE", payload: data }),
  };
};
export default connect(null, mapDispatchToProps)(Profile);
