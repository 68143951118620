import React, { Component } from "react";
import Swal from "sweetalert2";
import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import { Grid } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { baseurlImg } from "../../../Components/BaseUrl";
import API from "../../../utils/API";
class Listpages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    const page = 1;
    const perPage = 5;
    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });

    API.get(`/pages?page=${page}&perPage=${perPage}`)
    .then(async (response) => {
      await response.data.pages;
      this.setState({ isLoading: false, pages: response.data.pages });

    })
      .catch((err) => console.log("error"));
  };
  handelDelete = (page, e) => {
    e.preventDefault();
    if (!page || page < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/page/" + page)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.fetchData();
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  render() {
    const lang = localStorage.getItem("lang") || "en";

    const columns = [
      {
        title: "Page Title",
        dataIndex: "seotitle_en",
        sorter: (a, b) => a.seotitle_en.length - b.seotitle_en.length,
      },

      {
        title: "Image",
        render: (text, record) => (
          <img
            style={{ height: "90px", width: "120px" }}
            src={baseurlImg + "/public/uploads/pages/" + record.image}
            alt={record.image}
          />
        ),
      },

      {
        title: lang == "en" ? "Actions" : "العمليات",
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              className="btn btn-sm btn-primary shadow  "
              to={"edit-page/" + record.id}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <a
              className="btn btn-danger btn-sm shadow  "
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>{" "}
          </div>
        ),
      },
    ];

    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Pages</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
               
                      <Table
                      className="table-striped"
                      loading={this.state.isLoading}
                      onChange={handleTableChange}
                      pagination={{
                        pageSizeOptions: ["5","10", "20", "50", "100"],
                        total:
                          this.state?.pages?.total > 0 &&
                          this.state.pages.total,
                        pageSize: this.state.pages.per_page,
                        current: this.state.pages.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto", textAlign: "right" }}
                      columns={columns}
                
                      dataSource={
                        this.state?.pages?.data
                          ? this.state?.pages?.data
                          : []
                      }
                      rowKey={(record) => record}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Listpages;
