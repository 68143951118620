import React, { Component } from "react";
import $ from "jquery";
import cookie from "js-cookie";

import { baseurlImg } from "../../../Components/BaseUrl";

import JoditEditor from "jodit-react";
import Swal from "sweetalert2";
import Select from "react-select";
import jQuery from "jquery";
import API from "../../../utils/API";
const lang = localStorage.getItem("lang") || "en";
const config = {
  readonly: false,
  uploader: {
    url: "https://services.findproperties.ae/api/uploadpageimage",
  },
};
class Editpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allbeds: [],
      alllocations: [],
      locations: [],
      filterLocation: [],
      propertytypes: [],
      features: [],
      selectedfeature: [],
      title_en: "",
      title_ar: "",
      propertytype: 0,
      purpose: 0,
      selectedLocation: "",
      price_from: 0,
      price_to: 0,
      bath_room: -1,
      bed_room: -1,
      rent_frequence: 0,
      fulltext: "",
      description_en: "",
      description_ar: "",
      image: null,
      imageShow: null,
      //
      image_ar: null,
      imageShow_ar: null,

      alttext_en: "",
      focuskeyphrases_en: "",
      seotitle_en: "",
      metadescription_en: "",

      alttext_ar: "",
      focuskeyphrases_ar: "",
      seotitle_ar: "",
      metadescription_ar: "",
      selectPlace: "",
    };
  }
  componentDidMount() {
    API.get("/bedsandbaths")
      .then((response) => {
        this.setState({ allbeds: response.data.beds });
      })
      .catch((err) => console.log("error"));

    let id = this.props.match.params.id;

    API.get("/page/" + id)
      .then((response) => {
        this.setState({
          title_en: response.data.page.title_en,
          title_ar: response.data.page.title_ar,
          purpose: response.data.page.purpose,
          propertytype_id: response.data.page.propertytype_id,
          propertytypeName_en: response.data.page.typeName_en,
          propertytypeName_ar: response.data.page.typeName_ar,
          price_from: response.data.page.price_from,
          price_to: response.data.page.price_to,
          usedImage: response.data.page.image,
          usedImage_ar: response.data.page.image_ar,
          beds: response.data.page.beds,
          rent_frequence: response.data.page.rent_frequence,
          description_en: response.data.page.description_en,
          description_ar: response.data.page.description_ar,
          alttext_en: response.data.page.alt_text,
          alttext_ar: response.data.page.alt_text_ar,
          focuskeyphrases_en: response.data.page.keyphrases_en,
          focuskeyphrases_ar: response.data.page.keyphrases_ar,
          seotitle_en: response.data.page.seotitle_en,
          seotitle_ar: response.data.page.seotitle_ar,
          metadescription_en: response.data.page.meta_en,
          metadescription_ar: response.data.page.meta_ar,
          selectPlace:
            lang == "en"
              ? response.data.fulladdress
              : response.data.fulladdress_ar,
        });
      })
      .catch((err) => console.log("error"));

    this.fetchData();
  }
  handlePage = () => {};

  fetchData = () => {
    API.get("/usedlocation")
      .then((response) => {
        this.setState({
          alllocations:
            lang == "en" ? response.data.locations : response.data.locations_ar,
        });
      })
      .catch((err) => console.log("error"));

    this.setState({ isLoading: true });
    API.get("/features")
      .then((response) => {
        this.setState({
          features:
            lang == "en"
              ? response.data.allfeatures_en
              : response.data.allfeatures_ar,
        });
      })
      .catch((err) => console.log("error"));
    API.get("/propertytypes")
      .then((response) => {
        this.setState({ propertytypes: response.data.propertytypes });
      })
      .catch((e) => console.log("error"));
  };
  handlePropertytype = (e) => {
    this.setState({ propertytype: e.target.value });
  };
  handlePurpose = (e) => {
    e.preventDefault();
    this.setState({ purpose: e.target.value });
  };

  setSelectedFeature = (e) => {
    this.setState({ selectedfeature: e });
  };

  handlePrice_from = (e) => {
    this.setState({ price_from: e.target.value });
  };
  handlePrice_to = (e) => {
    this.setState({ price_to: e.target.value });
  };

  handleBathroom = (e) => {
    this.setState({ bath_room: e.target.value });
  };
  handleBedroom = (e) => {
    this.setState({ bed_room: e.target.value });
  };

  handlePer = (e) => {
    this.setState({ rent_frequence: e.target.value });
  };

 

  handleFocuskeyphrases_en = (e) => {
    e.preventDefault();
    this.setState({ focuskeyphrases_en: e.target.value });
  };

  handleSeotitle_en = (e) => {
    e.preventDefault();
    this.setState({ seotitle_en: e.target.value });
  };
  handleMetadescription_en = (e) => {
    e.preventDefault();
    this.setState({ metadescription_en: e.target.value });
  };

  handleAlttext_en = (e) => {
    e.preventDefault();
    this.setState({ alttext_en: e.target.value });
  };
  // arabic
  handleFocuskeyphrases_ar = (e) => {
    e.preventDefault();
    this.setState({ focuskeyphrases_ar: e.target.value });
  };

  handleSeotitle_ar = (e) => {
    e.preventDefault();
    this.setState({ seotitle_ar: e.target.value });
  };

  handleMetadescription_ar = (e) => {
    e.preventDefault();
    this.setState({ metadescription_ar: e.target.value });
  };

  handleAlttext_ar = (e) => {
    e.preventDefault();
    this.setState({ alttext_ar: e.target.value });
  };

  handleUpload = (e) => {
    e.preventDefault();

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);

  
    this.setState({ imageShow: URL.createObjectURL(e.target.files[0]) });
  };

  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleUpload_ar = (e) => {
    e.preventDefault();

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage_ar(files[0]);

    this.setState({ imageShow_ar: URL.createObjectURL(e.target.files[0]) });
  };

  createImage_ar(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image_ar: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }

  handleSaveData = (e) => {
    var location_selected = jQuery("#location_selected").val();
    if (this.state.selectPlace == "") {
      jQuery("#location_selected").css("border", "2px solid red");
      return;
    } else {
      jQuery("#location_selected").css("border", "2px solid #eee");
    }
    const token = cookie.get("token");
    let id = this.props.match.params.id;

    const data = {
      purpose: this.state.purpose,
      selectPlace: this.state.selectPlace,
      propertytype: this.state.propertytype_id,

      beds: this.state.bed_room,
      title_en: this.state.title_en,
      title_ar: this.state.title_ar,
      description_en: this.state.description_en,
      description_ar: this.state.description_ar,
      image: this.state.image,
      image_ar: this.state.image_ar,
      alttext_en: this.state.alttext_en,
      alttext_ar: this.state.alttext_ar,
      focuskeyphrases_en: this.state.focuskeyphrases_en,
      focuskeyphrases_ar: this.state.focuskeyphrases_ar,
      seotitle_en: this.state.seotitle_en,
      seotitle_ar: this.state.seotitle_ar,
      metadescription_en: this.state.metadescription_en,
      metadescription_ar: this.state.metadescription_ar,
    };

    API.put("/page/" + id, data)
      .then((response) => {
        Swal.fire({
          title: "Done!",
          text: "Data updated",
          icon: "success",
          timer: 2000,
          button: false,
        });

        this.props.history.push("/pages");
      })
      .catch((err) => console.log("error"));
  };

  handleLocation = (opt) => {
    if (opt.value > 0) {
      $("#location_selected").val(opt.label);
      this.setState({ selectPlace: opt });
    }
  };

  handleTitleEn = (e) => {
    e.preventDefault();
    this.setState({ title_en: e.target.value });
  };

  handleTitleAr = (e) => {
    e.preventDefault();
    this.setState({ title_ar: e.target.value });
  };
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Edit Page</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label>Title English</label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.title_en}
                          className="form-control wizard-required"
                          id="title_en"
                          onChange={this.handleTitleEn}
                          maxLength={65}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label>Title Arabic</label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={this.state.title_ar}
                          className="form-control wizard-required"
                          id="title_en"
                          onChange={this.handleTitleAr}
                          maxLength={65}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3">
                      <select
                        className="form-select "
                        style={{ height: "3.5rem" }}
                        aria-label="form-select"
                        onChange={this.handlePurpose}
                      >
                        {this.state.purpose == 0 ? (
                          <option selected value="0">
                            Purpose
                          </option>
                        ) : null}

                        {this.state.purpose == 1 ? (
                          <>
                            <option value="1">
                              {lang == "en" ? "For Rent" : "للايجار"}
                            </option>
                            <option value="2">
                              {lang == "en" ? "For Sale" : "للبيع"}
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="2">
                              {lang == "en" ? "For Sale" : "للبيع"}
                            </option>
                            <option value="1">
                              {lang == "en" ? "For Rent" : "للايجار"}
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <Select
                        className=" wizard-required"
                        options={this.state.alllocations}
                        onChange={(opt) => this.handleLocation(opt)}
                        value={this.state.selectPlace}
                        id="location_selected"
                      />
                      {/* <Autocomplete
                        
                        options={this.state.filterLocation}
                        onChange={(event, newValue) => {
                          this.setState({ selectedLocation: newValue });
                       // const data= {selectedLocation: newValue, purpose: this.state.purpose,propertytype:this.state.propertytype}
  
                        //  this.fetchProperty(data)
                       
                        }}
                        getOptionLabel={(option) => option.location}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder=""
                          />
                        )}
                      /> */}
                    </div>
                    <div className="col-sm-3">
                      <select
                        className="form-select wizard-required"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        onChange={this.handlePropertytype}
                      >
                        <option value={this.state.propertytype_id}>
                          {lang == "en"
                            ? this.state.propertytypeName_en
                            : this.state.propertytypeName_ar}
                        </option>

                        {this.state.propertytypes.map((ppty) => (
                          <>
                            {ppty.id != this.state.propertytype_id ? (
                              <option value={ppty.id}>
                                {lang == "en"
                                  ? ppty.typeName_en
                                  : ppty.typeName_ar}
                              </option>
                            ) : null}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{lang == "en" ? "Bed room" : "الغرف"}</label>

                        <select
                          className="form-select form-select-lg mb-3"
                          style={{ fontSize: "unset", height: "2.75rem" }}
                          aria-label="form-select-lg example"
                          onChange={this.handleBedroom}
                        >
                          <option value={this.state.beds}>
                            {this.state.beds < 1
                              ? this.state.beds == -1
                                ? "select"
                                : "studio"
                              : this.state.beds}
                          </option>
                          {this.state.allbeds.map((bed) => (
                            <>
                              {bed.id != this.state.beds ? (
                                <option value={bed.id}>{bed.name}</option>
                              ) : null}
                            </>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          English
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Arabic
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row mt-5">
                          <div className="col-md-4">
                            <label>
                              <i className="bi bi-camera"></i>&nbsp;&nbsp; Add
                              Media{" "}
                            </label>
                            <input
                              type="file"
                              className="form-file-input form-control"
                              onChange={this.handleUpload}
                              style={{ height: "40px" }}
                            />
                            <div className="mt-10" style={{ margin: "20px" }}>
                              {this.state.image != null ? (
                                <img
                                  src={this.state.imageShow}
                                  style={{ width: "700px", height: "350px" }}
                                />
                              ) : (
                                <div>
                                  {" "}
                                  {this.state.usedImage != null ? (
                                    <img
                                      src={
                                        baseurlImg +
                                        "/public/uploads/pages/" +
                                        this.state.usedImage
                                      }
                                      style={{
                                        width: "700px",
                                        height: "350px",
                                      }}
                                    />
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <label>Alt Text</label>
                            <input
                              type="text"
                              onChange={this.handleAlttext_en}
                              className=" form-control"
                              value={this.state.alttext_en}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label>Description</label>
                              <JoditEditor
                                //ref={editor}
                                value={this.state.description_en}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  this.setState({ description_en: newContent })
                                } // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent) => {}}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-8">
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <div className="mt-5">
                                  <label>Focus keyphrases</label>
                                  <input
                                    type="text"
                                    className="form-control m-2"
                                    onChange={this.handleFocuskeyphrases_en}
                                    value={this.state.focuskeyphrases_en}
                                  />
                                  <label>SEO Title</label>
                                  <input
                                    type="text"
                                    className=" form-control m-2"
                                    onChange={this.handleSeotitle_en}
                                    value={this.state.seotitle_en}
                                  />
                                  <label>Meta description</label>
                                  <textarea
                                    type="text"
                                    className=" form-control m-2"
                                    rows={3}
                                    onChange={this.handleMetadescription_en}
                                    value={this.state.metadescription_en}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="row mt-5" dir="rtl">
                          <div className="col-md-4">
                            <label>
                              <i className="bi bi-camera"></i>&nbsp;&nbsp; Add
                              Media{" "}
                            </label>
                            <input
                              type="file"
                              className="form-file-input form-control"
                              onChange={this.handleUpload_ar}
                              style={{ height: "40px" }}
                            />
                            <div className="mt-10" style={{ margin: "20px" }}>
                              {this.state.image_ar != null ? (
                                <img
                                  src={this.state.imageShow_ar}
                                  style={{ width: "700px", height: "350px" }}
                                />
                              ) : (
                                <div>
                                  {" "}
                                  {this.state.usedImage_ar != null ? (
                                    <img
                                      src={
                                        baseurlImg +
                                        "/public/uploads/pages/" +
                                        this.state.usedImage_ar
                                      }
                                      style={{
                                        width: "700px",
                                        height: "350px",
                                      }}
                                    />
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <label>Alt Text</label>
                            <input
                              type="text"
                              onChange={this.handleAlttext_ar}
                              className=" form-control"
                              value={this.state.alttext_ar}
                            />
                          </div>
                        </div>
                        <div className="row" dir="rtl">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label>Description</label>
                              <JoditEditor
                                //ref={editor}
                                value={this.state.description_ar}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  this.setState({ description_ar: newContent })
                                } // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent) => {}}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row" dir="rtl">
                          <div className="col-md-8">
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <div className="mt-5">
                                  <label>Focus keyphrases</label>
                                  <input
                                    type="text"
                                    className="form-control m-2"
                                    onChange={this.handleFocuskeyphrases_ar}
                                    value={this.state.focuskeyphrases_ar}
                                  />
                                  <label>SEO Title</label>
                                  <input
                                    type="text"
                                    className=" form-control m-2"
                                    onChange={this.handleSeotitle_ar}
                                    value={this.state.seotitle_ar}
                                  />
                                  <label>Meta description</label>
                                  <textarea
                                    type="text"
                                    className=" form-control m-2"
                                    rows={3}
                                    onChange={this.handleMetadescription_ar}
                                    value={this.state.metadescription_ar}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-primary me-3 mb-3 create-btn"
                          onClick={this.handleSaveData}
                        >
                          {" "}
                          Update Page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Editpage;
