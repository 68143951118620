import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import { useSelector } from 'react-redux';



 const SeoPrivate = ({ component: Component, ...rest }) => {
  const { token } = useSelector(state => state.auth)
  const user = useSelector(state => state.auth.user)
  
 
  return (
      <Route {...rest} render={(props) => (
          (token && user.role==6)
              ? <Component {...props} />
              : <Redirect to='/' />
      )} />
  )
}


  export default SeoPrivate