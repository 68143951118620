import React from 'react'
import './Bye.css';
function Bye() {
  return (
    <div>
    <div class="mytitle" >
 <h1 class="animate-charcter">Thank you for choosing findproperties</h1>
 </div>

</div>
  )
}

export default Bye