import React, { Component } from 'react';
import Swal from 'sweetalert2'
import 'antd/dist/antd.css';
import {Table} from 'antd'
import {itemRender,onShowSizeChange} from "../paginationfunction"
import "../antdstyle.css"
import { Grid } from  'react-loader-spinner'
import {Link} from 'react-router-dom'
import {  baseurlImg } from '../../Components/BaseUrl';
import API from '../../utils/API';
import { store } from '../../store';

class Listblogs extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            blogs:[],
            isLoading:false
        }
    }
    componentDidMount(){
      const page = 1;
      const perPage = 5;
      this.fetchData(page, perPage);
    }
 
    fetchData =(page, perPage) =>{
      
        this.setState({isLoading:true})

        API.get(`/blogs?page=${page}&perPage=${perPage}`)
        .then(async (response) => {
          await response.data.blogs;
          this.setState({ isLoading: false, blogs: response.data.blogs });

        })
        .catch(err =>console.log("error"))
    }
        handelDelete = (blog, e) =>{
            e.preventDefault();
            if(!blog || blog <1)
            {
                return
            } else {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        API.delete("/blog/"+blog)
                        .then(response =>{
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                              )
                         this.fetchData();
                        })
                        .catch(err => console.log(err))
                    }
                  })
            }
        }

    render() {
        const lang =localStorage.getItem("lang") || "en";

        const userdata = store.getState((state) => state);
       
        let role = userdata.auth.user.role;
       



        const columns = [        
   
          {
              title: "Blog Title",
              dataIndex: 'title_en',
              sorter: (a, b) => a.title_en.length - b.title_en.length,
            },
      
              {
                title: "Image",
                render:(text,record)=>(
               
                  <img style={{ height: '90px', width: '120px' }} src={baseurlImg + '/uploads/blogs/' + record.image} alt={record.image} />
             
                )
      
            },
      
  
       
  
          {
            title: lang=="en"?"Actions":"العمليات",
            render: (text, record) => (
              <div className="ms-auto">
                        
                        <Link className="btn btn-sm btn-primary shadow  " to={"edit-blog/" + record.id} ><i className="fa fa-edit"></i></Link>
                      <a href={"https://findproperties.ae/blogs/" + record.slug_en} className="btn btn-warning btn-sm m-2" target="_blank"><i className="fa fa-eye"></i></a>
                     {role == 1?<a className="btn btn-danger btn-sm shadow  " onClick={this.handelDelete.bind(this, record.id)}><i className="fa fa-trash" ></i></a>:null} 
                 
              </div>
              ),
          }, 
        ]
        const handleTableChange = (pagination) => {
          this.fetchData(pagination.current, pagination.pageSize);
        };

        return (
            <div> 
            <div className="container-fluid">
 <div className="row">
<div className="col-12">
<div className="card">
  <div className="card-header">
    <h4 className="card-title">Blogs</h4>
  </div>
  <div className="card-body">
    <div className="table-responsive">
 
    <Table
                        className="table-striped"
                        loading={this.state.isLoading}
                        onChange={handleTableChange}
                        pagination={{
                          pageSizeOptions: ["5","10", "20", "50", "100"],
                          total:
                            this.state?.blogs?.total > 0 &&
                            this.state.blogs.total,
                          pageSize: this.state.blogs.per_page,
                          current: this.state.blogs.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                  
                        dataSource={
                          this.state?.blogs?.data
                            ? this.state?.blogs?.data
                            : []
                        }
                        rowKey={(record) => record}
                      />
                                                
  
    </div>
  </div>
</div>
</div>



</div>
   




</div>

  </div>
        );
    }
}

export default Listblogs;