import React, { Component } from "react";

import i18next from "i18next";
//import swal from 'sweetalert';
import Swal from "sweetalert2";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
//import toast, { Toaster } from 'react-hot-toast';

import { ToastContainer, toast } from "react-toastify";
import { Grid } from "react-loader-spinner";

import { Link } from "react-router-dom";

import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import API from "../../../../utils/API";
//import { lang } from 'moment';

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ isLoading: true });
    API.get("/employees")
      .then((response) => {
        this.setState({ isLoading: false, employees: response.data.employees });
      })
      .catch((e) => console.log("error"));
  };
  // handle text input from english category

  handelDelete = (cat, e) => {
    e.preventDefault();

    if (!cat || cat < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/categories/" + cat)
            .then((response) => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.fetchData();
              //this.setState({categories:response.data.categories})
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  render() {
    const lang = localStorage.getItem("lang") || "en";

    const columns = [
      {
        title: lang == "en" ? "Employee Name" : "اسم الموظف",
        dataIndex: "name_en",
        sorter: (a, b) => a.name_en.length - b.name_en.length,
      },
      {
        title: lang == "en" ? "Employee Arabic Name " : "اسم الموظف بالعربية",
        dataIndex: "name_ar",
        sorter: (a, b) => a.name_ar.length - b.name_ar.length,
      },
      {
        title: lang == "en" ? "Email" : " البريد الالكتروني",
        dataIndex: "email",
        sorter: (a, b) => a.email.length - b.email.length,
      },
      {
        title: lang == "en" ? "Mobile" : "  الجوال",
        dataIndex: "mobile",
        sorter: (a, b) => a.mobile.length - b.mobile.length,
      },
      {
        title: lang == "en" ? "Profile" : "  الملف",
        render: (text, record) => (
          <>
            {record.profile != "" ||
            record.profile != null ||
            !record.profile ? (
              <img
                style={{ height: "70px", width: "70px" }}
                src={baseurlImg + "/uploads/profiles/" + record.profile}
              />
            ) : (
              <img
                style={{ height: "70px", width: "70px" }}
                src={baseurlImg + "/uploads/profiles/no_avatar.png"}
              />
            )}
          </>
        ),
      },
      {
        title: lang == "en" ? "Status" : "الحالة",
        render: (text, record) => (
          <>
            {record.active == 1 ? (
              <span className="badge light badge-success">
                {lang == "en" ? "Active" : "نشط"}
              </span>
            ) : (
              <span className="badge light badge-danger">
                {lang == "en" ? "No active" : "غير نشط"}
              </span>
            )}
          </>
        ),
      },

      {
        title: lang == "en" ? "Actions" : "العمليات",
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              className="btn btn-primary shadow  sharp me-1"
              to={"edit-employee/" + record.id}
            >
              <i className="fa fa-edit"></i>
            </Link>
            <a
              className="btn btn-danger shadow  sharp"
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="col-xl-12">
            <div className="d-flex flex-wrap">
              <Link to="/addemp" className="btn btn-primary mb-2">
                + {lang == "en" ? " Add Employee" : "اضافة موظف"}
              </Link>
            </div>
          </div>
          {/*Modal to Add Category  */}

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">{i18next.t("employees")}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                 
                      <Table
                        className="table-striped"
                        loading={this.state.isLoading}
                        pagination={{
                          total: this.state.employees.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={this.state.employees}
                        rowKey={(record) => record}
                      />
                   
            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Employees;
