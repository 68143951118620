import React, { Component } from "react";

import API from "../../../utils/API";

import "antd/dist/antd.css";
import { Table, Breadcrumb ,DatePicker} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import { Grid } from "react-loader-spinner";
import { Link } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
function RelativeTime({ date }) {
  if (!date) {
    return <span></span>; // Return empty if date is null or undefined
  }
  return <span>{moment(date).fromNow()}</span>;
}
class AgentLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      dateRange:null
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    const dateRange = this.state.dateRange
    //
    this.fetchData(page, perPage,dateRange);
  }

  fetchData = (page, perPage,dateRange) => {
    this.setState({ isLoading: true });
    API.get(`/agentleads?page=${page}&perPage=${perPage}&date=${dateRange}`)
      .then(async (response) => {
        var data = await response.data.leads;
        this.setState({ isLoading: false, leads: response.data.leads });
      })
      .catch((e) => console.log("error"));
  };
  render() {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";
    const columns = [
      {
        title: lang == "en" ? "Title" : "عنوان العقار",
        render: (text, record) => (
          <a
          href={"https://findproperties.ae/single-property/" + record.id}
       
          title={lang == "en" ? "Show" : "عرض"}
          target="_blank"
        >
          {lang == "en" ? record.title_en : record.title_ar}
           </a>
        ),
      },
      {
        title: lang == "en" ? "Location" : "الموقع",
        render: (text, record) => (
          <>
            {lang == "en"
              ? record.emirate_en + "-" + record.area_en
              : record.emirate_ar + "-" + record.area_ar}
          </>
        ),
      },
      {
        title: lang == "en" ? "Clicks" : " ضغط على العقار",
        render: (text, record) => (
          <span class="badge bg-primary">{record.viewing_count}</span>
        ),
      },
      {
        title: "Last click",
        render: (text, record) => (
          <RelativeTime date={record.last_viewing} />
          // <span >{record.last_viewing}</span>
        ),
      },
      {
        title: lang == "en" ? "Call leads" : " محاولات اتصال",
        render: (text, record) => (
          <span class="badge bg-danger text-black">{record.calls_count}</span>
        ),
      },
      {
        title: "Last call",
        render: (text, record) => (
          <RelativeTime date={record.last_call} />
          // <span >{record.last_call}</span>
        ),
      },
      {
        title: lang == "en" ? "Whatsapp leads" : " محاولات واتساب",
        render: (text, record) => (
          <span class="badge bg-success text-black">
            {record.whatsapp_count}
          </span>
        ),
      },
      {
        title: "Last message",
        render: (text, record) => (
          <RelativeTime date={record.last_whatsapp} />
          // <span >{record.last_whatsapp}</span>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      const dateRange = this.state.dateRange
      this.fetchData(pagination.current, pagination.pageSize,dateRange);
    };

    const handleRangeDate = (date, dateString) => {
      this.setState({dateRange:date?dateString:""})
      const page = 1;
      const perPage = 10;
      const dateRange = date?dateString:""
      this.fetchData(page, perPage,dateRange);

      

    }

    return (
      <div>
        <div className="container-fluid">
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
        <Breadcrumb.Item>
              <Link to="/">{lang=="en"?"Dashboard":"لوحة البيانات"}</Link>
            
            </Breadcrumb.Item>
            <Breadcrumb.Item>Leads</Breadcrumb.Item>
          </Breadcrumb>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <div style={{display:'flex',flexDirection:'row',marginBottom:'15px',justifyContent:'space-between'}}>
                
                  
                <div>
                <label style={{padding:'10px'}}>Date </label>
                <RangePicker
                onChange={handleRangeDate}
                  className="custom-range-picker"
                  dropdownClassName="custom-dropdown"
                />

                </div>
              </div>

                  <div className="table-responsive">
                    <Table
                      onChange={handleTableChange}
                      loading={this.state.isLoading}
                      className="table-striped"
                      pagination={{
                        pageSizeOptions: ["10", "20", "50", "100"],
                        total:
                          this.state?.leads?.total > 0 &&
                          this.state.leads.total,
                        pageSize: this.state.leads.per_page,
                        current: this.state.leads.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={
                        this.state?.leads?.data ? this.state?.leads?.data : []
                      }
                      rowKey={(record) => record}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentLeads;
